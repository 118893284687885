import React from 'react'

// Customizable Area Start
import { Grid, Hidden, Typography, Box, Chip, FormControlLabel, Checkbox, Button } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel';
import { slider1, imgMax } from './assets'
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            "width": "198px",
            "height": "49px",
            "padding": "6px 10px",
            "borderRadius": "36px",
            "background": "#9C292D",
            color: "#FFF",
            fontSize: "16px",
            fontWeight: 400,
            textTransform: "none",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#9C292D"
            },
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

// Customizable Area Start
import LandingPageController, {
    Props,
    configJSON
} from "./LandingPageController";
class CarouselDisplay extends LandingPageController {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Box sx={webStyles.root}>
                    <Grid container>

                        <Hidden xsDown>
                            <Grid item lg={6} sm={6}>
                                <Carousel
                                    navButtonsAlwaysInvisible={true}
                                    indicatorContainerProps={{
                                        style: {
                                            position: "absolute",
                                            top: "0px",
                                            left: "250px",
                                            marginTop: "40px"
                                        }
                                    }}
                                    indicatorIconButtonProps={{
                                        style: {
                                            color: '#E7E7E7'
                                        }
                                    }}
                                    activeIndicatorIconButtonProps={{
                                        style: {
                                            color: '#FFF'
                                        }
                                    }}
                                >
                                    {[1, 2, 3].map(() => (
                                        <Box>
                                            <img style={webStyles.imageRage as React.CSSProperties} src={slider1} alt="img" />
                                            <Box style={webStyles.imgContainer as React.CSSProperties}>
                                                <Typography variant='h1' color="secondary">Lorem Ipsum is simply</Typography>
                                                <Typography variant='h4' color="secondary">Lorem Ipsum is simply</Typography>
                                            </Box>
                                        </Box>
                                    ))}

                                </Carousel>
                            </Grid>
                        </Hidden>

                        <Grid item lg={6} sm={6} xs={12}>
                            <Grid container justifyContent="flex-end" >
                                <img src={imgMax} alt="img2" height={68} width={43} />
                            </Grid>


                            <Grid container direction="column" justifyContent="center" alignItems="center">

                                <Grid direction="column" style={webStyles.formContainer} container justifyContent="center" alignItems="center">

                                    <Typography variant='h6'>Welcome to ReserveMax.ai..!</Typography>
                                    <Chip style={webStyles.chipRage} label={<Typography variant='body1'>Login</Typography>} />
                                    <Typography style={{ marginTop: "30px", color: "#5B5B5B" }} variant='body1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry  Ipsum Ipsum Ipsum.</Typography>

                                    <Grid item xs={12} style={{ width: "100%" }}>
                                        <Typography style={{ marginTop: "34px" }} variant='body1'>Email address</Typography>
                                        <Box sx={{ width: { xs: "100%", md: "96%", lg: "96%" } }}>
                                            <input style={webStyles.inputRage} />
                                        </Box>

                                        <Typography style={{ marginTop: "23px" }} variant='body1'>Password</Typography>
                                        <Box sx={{ width: { xs: "100%", md: "96%", lg: "96%" } }}>
                                            <input style={webStyles.inputRage} />
                                        </Box>
                                    </Grid>

                                    <Grid style={{ marginTop: "16px" }} container justifyContent="space-between" alignItems="center">
                                        <FormControlLabel
                                            control={<Checkbox color='primary' name="checkedA" />}
                                            label={<Typography variant='caption'>Rememebr me</Typography>}
                                        />
                                        <StyledButton variant='contained'>Login</StyledButton>
                                    </Grid>

                                    <Typography style={{ marginTop: "47px", color: "#333333" }} variant='subtitle1'>Don’t have an account yet?</Typography>
                                    <Typography style={{ color: "#4743E0" }} variant='caption'>learn more</Typography>

                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                </Box>
            </ThemeProvider >

        )
    }
}

const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: '#FFF' }
    },
    typography: {
        // "fontFamily": `Abadi-MT-Condensed-Extra-Bold-Regular-Font,Rubik Microbe`,
        h1: {
            fontSize: "37px",
            fontWeight: 800,
            fontStyle: 'normal'
        },
        h4: {
            fontSize: "25px",
            fontWeight: 400,
            fontStyle: 'normal'
        },
        h6: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 800
        },
        body1: {
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        caption: {
            fontSize: "12px",
            fontStyle: 'normal',
            fontWeight: 300
        },
        subtitle1: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400
        }
    },
});

const webStyles = {
    root: {
        padding: { lg: "38px 48px", xs: "10px 12px", md: "38px 48px" }
    },
    Icon: {
        width: '13px',
        height: '13px',
        borderRadius: "50%",
        backgroundColor: "#E7E7E7"
    },
    imageRage: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    imgContainer: {
        width: "435px",
        position: "absolute",
        top: "32px",
        left: "40px"
    },
    chipRage: {
        width: "146px",
        height: "40px",
        marginTop: "17px",
        borderRadius: "33px"
    },
    formContainer: {
        height: "auto",
        maxWidth: "454px"
    },
    inputRage: {
        marginTop: "18px",
        width: "100%",
        height: "54px",
        borderRadius: "40px",
        border: "1px solid #9E896A",
        padding: "19px 30px",
        color: "#ACACAC",
        fontSize: "15px",
        fontWeight: 300
    }
}
export default CarouselDisplay;

